import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateVehicles,
  urlEditVehicles,
  urlGetVehicleTypes,
} from "../../../../api/URLs/vehiculos";
import { urlGetVehicleBrands } from "../../../../api/URLs/entidadesSecundarias/marcas";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { vehiclesSchema } from "../../../../schemas/Camiones/Vehicles/vehiclesSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basic
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomCheckbox from "../../../../components/Tools/Inputs/CustomCheckbox";

const FormVehiculos = ({ vehicle, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [gpsChecked, setGpsChecked] = useState(vehicle ? vehicle.gps : false);

  //Get vehicle types and brands
  const getParellel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlGetVehicleTypes));
    promise_array.push(axiosWithAuth.get(urlGetVehicleBrands));
    try {
      const api_responses = await Promise.all(promise_array);
      const [vehicleTypes_response, vehicleBrands_response] = api_responses;
      setVehicleTypes(vehicleTypes_response.data);
      setVehicleBrands(vehicleBrands_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Initial Values
  let newValues, updateValues;
  if (vehicle) {
    updateValues = {
      patente: vehicle.patente ? vehicle.patente : "",
      numChasis: vehicle.numChasis ? vehicle.numChasis : "",
      numMotor: vehicle.numMotor ? vehicle.numMotor : "",
      numMovil: vehicle.numMovil ? vehicle.numMovil : "",
      numTitulo: vehicle.numTitulo ? vehicle.numTitulo : "",
      numCedulaVerde: vehicle.numCedulaVerde ? vehicle.numCedulaVerde : "",
      numRuta: vehicle.numRuta ? vehicle.numRuta : "",
      numSeguro: vehicle.numSeguro ? vehicle.numSeguro : "",
      numVTV: vehicle.numVTV ? vehicle.numVTV : "",
      numCredPuerto: vehicle.numCredPuerto ? vehicle.numCredPuerto : "",
      fechaRuta: vehicle.fechaRuta ? vehicle.fechaRuta.slice(0, 10) : "",
      fechaSeguro: vehicle.fechaSeguro ? vehicle.fechaSeguro.slice(0, 10) : "",
      fechaVTV: vehicle.fechaVTV ? vehicle.fechaVTV.slice(0, 10) : "",
      fechaMataFuego: vehicle.fechaMataFuego
        ? vehicle.fechaMataFuego.slice(0, 10)
        : "",
      fechaCredPuerto: vehicle.fechaCredPuerto
        ? vehicle.fechaCredPuerto.slice(0, 10)
        : "",
      gps: vehicle.gps ? vehicle.gps : false,
      es_propio: vehicle.es_propio ? vehicle.es_propio : false,
      link_gps: vehicle.link_gps ? vehicle.link_gps : "",
      usuario_gps: vehicle.usuario_gps ? vehicle.usuario_gps : "",
      clave_gps: vehicle.clave_gps ? vehicle.clave_gps : "",
    };
  } else {
    newValues = {
      patente: "",
      numChasis: "",
      numMotor: "",
      numMovil: "",
      numTitulo: "",
      numCedulaVerde: "",
      numRuta: "",
      numSeguro: "",
      numVTV: "",
      numCredPuerto: "",
      fechaRuta: "",
      fechaSeguro: "",
      fechaVTV: "",
      fechaMataFuego: "",
      fechaCredPuerto: "",
      gps: false,
      es_propio: false,
      link_gps: "",
      usuario_gps: "",
      clave_gps: "",
    };
  }
  const initialValues = vehicle ? updateValues : newValues;
  //VALIDACIONES Dropdown
  const validateTypeOption = () => {
    if (!selectedType && !selectedBrand) {
      handleError("Debes seleccionar una opcion.");
      return false;
    } else {
      return true;
    }
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    const selectAreSelected = validateTypeOption();
    if (!selectAreSelected) return;
    Object.keys(values).forEach((key) => {
      if (values[key] === "") {
        values[key] = null;
      }
    });
    const merged = {
      ...values,
      tipo: selectedType,
      id_marca: selectedBrand,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!vehicle) {
        api_response = await axiosWithAuth.post(urlCreateVehicles, merged);
      } else {
        api_response = await axiosWithAuth.put(urlEditVehicles, {
          ...merged,
          id: vehicle.id,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  //HANDLE IDs ON EDIT
  const handleEdit = () => {
    if (vehicle) {
      setSelectedType(vehicle.tipo);
      setSelectedBrand(vehicle.id_marca);
    }
  };

  useEffect(() => {
    getParellel();
    handleEdit();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={vehicle} text={"Vehiculo"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        validationSchema={vehiclesSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <div className="col-md-6">
                {/* TIPO */}
                <>
                  <Form.Label>Tipo de Vehiculo</Form.Label>
                  <CustomDropdown
                    options={vehicleTypes}
                    onSelect={(c) => setSelectedType(c.id)}
                    defaultOption={
                      vehicle ? { tipo: vehicle.desc_tipo_veh } : null
                    }
                    displayProperty={"tipo"}
                    btnName={"Seleccione un tipo"}
                  />
                </>
                {/* PATENTE */}
                <FormGroup>
                  <Form.Label>Patente</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Patente"
                    name="patente"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.patente}
                    isInvalid={
                      errors.patente && touched.patente && errors.patente
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.patente && touched.patente && errors.patente}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* NUMERO CHASIS */}
                <FormGroup>
                  <Form.Label>Numero de chasis</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Chasis"
                    name="numChasis"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numChasis}
                    isInvalid={
                      errors.numChasis && touched.numChasis && errors.numChasis
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numChasis && touched.numChasis && errors.numChasis}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* NUMERO MOVIL */}
                <FormGroup>
                  <Form.Label>Numero movil</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Movil"
                    name="numMovil"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numMovil}
                    isInvalid={
                      errors.numMovil && touched.numMovil && errors.numMovil
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numMovil && touched.numMovil && errors.numMovil}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* NUMERO RUTA */}
                <FormGroup>
                  <Form.Label>Numero Ruta</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ruta"
                    name="numRuta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numRuta}
                  />
                </FormGroup>
                {/* NUMERO SEGURO */}
                <FormGroup>
                  <Form.Label>Numero Seguro</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Seguro"
                    name="numSeguro"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numSeguro}
                  />
                </FormGroup>
                {/* NUMERO VTV */}
                <FormGroup>
                  <Form.Label>Numero VTV</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="VTV"
                    name="numVTV"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numVTV}
                  />
                </FormGroup>
                {/* NUMERO CREDENCIAL PUERTO */}
                <FormGroup>
                  <Form.Label>Numero Credencial Puerto</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Credencial Puerto"
                    name="numCredPuerto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numCredPuerto}
                  />
                </FormGroup>
                {/* NUMERO CEDULA VERDE */}
                <FormGroup>
                  <Form.Label>Numero Cedula Verde</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Cedula verde"
                    name="numCedulaVerde"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numCedulaVerde}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* MARCA */}
                <>
                  <Form.Label>Marca</Form.Label>
                  <CustomDropdown
                    options={vehicleBrands}
                    onSelect={(m) => setSelectedBrand(m.id)}
                    defaultOption={vehicle ? { marca: vehicle.marca } : null}
                    displayProperty={"marca"}
                    btnName={"Seleccione una marca"}
                  />
                </>
                {/* NUMERO MOTOR */}
                <FormGroup>
                  <Form.Label>Numero de motor</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Motor"
                    name="numMotor"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numMotor}
                  />
                </FormGroup>
                {/* NUMERO TITULO */}
                <FormGroup>
                  <Form.Label>Numero Titulo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titulo"
                    name="numTitulo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numTitulo}
                  />
                </FormGroup>
                {/* FECHA MATA FUEGO */}
                <FormGroup className="w-100">
                  <Form.Label>Fecha Mata Fuego</Form.Label>
                  <Form.Control
                    type="date"
                    name="fechaMataFuego"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fechaMataFuego}
                  />
                </FormGroup>
                {/* FECHA RUTA */}
                <FormGroup className="w-100">
                  <Form.Label>Fecha Ruta</Form.Label>
                  <Form.Control
                    type="date"
                    name="fechaRuta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fechaRuta}
                  />
                </FormGroup>
                {/* FECHA SEGURO */}
                <FormGroup className="w-100">
                  <Form.Label>Fecha Seguro</Form.Label>
                  <Form.Control
                    type="date"
                    name="fechaSeguro"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fechaSeguro}
                  />
                </FormGroup>
                {/* FECHA VTV */}
                <FormGroup className="w-100">
                  <Form.Label>Fecha VTV</Form.Label>
                  <Form.Control
                    type="date"
                    name="fechaVTV"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fechaVTV}
                  />
                </FormGroup>
                {/* FECHA CREDENCIAL PUERTO */}
                <FormGroup className="w-100">
                  <Form.Label>Fecha Credencial Puerto</Form.Label>
                  <Form.Control
                    type="date"
                    name="fechaCredPuerto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fechaCredPuerto}
                  />
                </FormGroup>
              </div>
              {/* ES PROPIO */}
              <CustomCheckbox name="es_propio" text="Vehículo propio?" />
              {/* GPS */}
              <CustomCheckbox
                name="gps"
                text="GPS"
                checked={gpsChecked}
                onChange={(e) => {
                  setGpsChecked(e.target.checked);
                  setFieldValue("gps", e.target.checked);
                }}
              />
            {gpsChecked === true && (
              <>
                <FormGroup>
                  <Form.Label>Link GPS</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Link GPS"
                    name="link_gps"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.link_gps}
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Usuario GPS</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Usuario GPS"
                    name="usuario_gps"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.usuario_gps}
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Clave GPS</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Clave GPS"
                    name="clave_gps"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clave_gps}
                  />
                </FormGroup>
              </>
            )}
            </div>
            {/* BUTTON */}
            <div className="col-md-6 offset-md-3">
              <ButtonSubmit
                prop={vehicle}
                isSubmitting={isSubmitting}
                loading={sendLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormVehiculos;
