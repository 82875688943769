import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//API Routes
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlSwitchEntities } from "../../../../../api/URLs/utilities";
import { urlGetVehicles } from "../../../../../api/URLs/vehiculos";
//Form
import FormVehiculos from "../../forms/Form";
//Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
//Basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../../components/Tools/Basics/NoWrapContainer";
//--Modal
import { useModal } from "../../../../../hooks/useModal";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import ModalPlain from "../../../../../components/Modals/ModalPlain";

const Vehiculos = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //States
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(false);

  //GET vehicles
  const getVehicles = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetVehicles);
      setVehicles(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Delete vehicle
  const handleSwitchActive = async (id) => {
    const merged = { nombre_entidad: "vehiculos", id_entidad: id };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.post(urlSwitchEntities, merged);
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Confirmation delete
  const confirmDelete = (vehicle) => {
    if (
      window.confirm(`¿Confirmar eliminación del vehiculo: ${vehicle.patente}?`)
    )
      handleSwitchActive(vehicle.id);
  };

  //BTN Actions
  const handleCreate = () => {
    setSelectedVehicle(null);
    setShowModalPlain(true);
  };

  const handleEdit = async (v) => {
    setSelectedVehicle(v);
    setShowModalPlain(true);
  };

  const actionButton = (vehicle) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleEdit(vehicle)} />
        <CustomBtn
          type={"delete_table"}
          onClick={() => confirmDelete(vehicle)}
        />
        <CustomBtn
          type={"details_table"}
          onClick={() =>
            navigate(`/vehiculos/detalles/${vehicle.id}`, {
              state: { vehicle },
            })
          }
        />
      </NoWrapContainer>
    );
  };

  //Data table
  const columns = [
    { field: "id", header: "Id" },
    { field: "desc_tipo_veh", header: "Tipo", sortable: true },
    { field: "patente", header: "Patente" },
    { field: "numMovil", header: "Movil" },
    { field: "marca", header: "Marca" },
    {
      field: "es_propio",
      header: "Propio",
      formater: "boolean_color",
      sortable: true,
    },
    {field: "gps" , header: "GPS", formater: "boolean_color", sortable : true},
    { body: actionButton },
  ];

  const buttonsTrips = [
    {
      type: "plus_table_header",
      text: " Vehiculo",
      click: () => handleCreate(),
    },
  ];
  
  useEffect(() => {
    getVehicles();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={vehicles}
        columns={columns}
        keyProp={"id"}
        loading={loading}
        title={"Vehiculos"}
        textFilter
        buttons={buttonsTrips}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormVehiculos
            vehicle={selectedVehicle}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default Vehiculos;
