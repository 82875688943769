//Tables
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import TableRows from "../../../../components/Tools/Tables/TableRows/TableRows";

const DetalleVehiculo = ({ vehicle }) => {
  //esto se hace asi para no renegar con rows y sus formatters
  const vehicleData = {
    ...vehicle,
    link_gps: vehicle.link_gps ? vehicle.link_gps : "NO",
    usuario_gps: vehicle.usuario_gps ? vehicle.usuario_gps : "NO",
    clave_gps: vehicle.clave_gps ? vehicle.clave_gps : "NO",
  }
  //Datos tabla Detalles
  const rows = [
    {
      first_title: "Id:",
      first_prop: "id",
      second_title: "Movil:",
      second_prop: "numMovil",
    },

    {
      first_title: "Tipo:",
      first_prop: "desc_tipo_veh",
      second_title: "Motor:",
      second_prop: "numMotor",
    },
    {
      first_title: "Marca:",
      first_prop: "marca",
      second_title: "Chasis:",
      second_prop: "numChasis",
    },
    {
      first_title: "Titulo:",
      first_prop: "numTitulo",
      second_title: "Propio:",
      second_prop: "es_propio",
      second_formater: "boolean",
    },
    {
      first_title: "Ced. Verde:",
      first_prop: "numCedulaVerde",
    },
    {
      first_title: "Link GPS:",
      first_prop: "link_gps",
    },
    
    {
      first_title: "Usuario GPS:",
      first_prop: "usuario_gps",
      second_title: "Clave GPS:",
      second_prop: "clave_gps",
    },

  ];

  //Datos tabla Vencimientos
  const dates = [
    {
      first_title: "Cred. Puerto:",
      first_prop: "numCredPuerto",
      second_title: "Fecha:",
      second_prop: "fechaCredPuerto",
      second_formater: "date",
    },
    {
      first_title: "Seguro:",
      first_prop: "numSeguro",
      second_title: "Fecha:",
      second_prop: "fechaSeguro",
      second_formater: "date",
    },
    {
      first_title: "VTV:",
      first_prop: "numVTV",
      second_title: "Fecha:",
      second_prop: "fechaVTV",
      second_formater: "date",
    },
    {
      first_title: "Ruta:",
      first_prop: "numRuta",
      second_title: "Fecha:",
      second_prop: "fechaRuta",
      second_formater: "date",
    },
    {
      first_title: "Matafuego:",
      first_prop: "",
      second_title: "Fecha:",
      second_prop: "fechaMataFuego",
      second_formater: "date",
    },
  ];
  return (
    <div className="row">
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <TableRows
            title={"Datos"}
            data={vehicleData}
            rows={rows}
            scrollSize={"md"}
          />
        </BoxContainer>
      </div>
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <TableRows
            title={"Vencimientos"}
            data={vehicle}
            rows={dates}
            scrollSize={"md"}
          />
        </BoxContainer>
      </div>
    </div>
  );
};

export default DetalleVehiculo;
